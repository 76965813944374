exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-content-1-1-index-js": () => import("./../../../src/pages/content1-1/index.js" /* webpackChunkName: "component---src-pages-content-1-1-index-js" */),
  "component---src-pages-content-1-2-index-js": () => import("./../../../src/pages/content1-2/index.js" /* webpackChunkName: "component---src-pages-content-1-2-index-js" */),
  "component---src-pages-content-1-3-index-js": () => import("./../../../src/pages/content1-3/index.js" /* webpackChunkName: "component---src-pages-content-1-3-index-js" */),
  "component---src-pages-content-2-1-index-js": () => import("./../../../src/pages/content2-1/index.js" /* webpackChunkName: "component---src-pages-content-2-1-index-js" */),
  "component---src-pages-content-2-2-index-js": () => import("./../../../src/pages/content2-2/index.js" /* webpackChunkName: "component---src-pages-content-2-2-index-js" */),
  "component---src-pages-content-2-3-index-js": () => import("./../../../src/pages/content2-3/index.js" /* webpackChunkName: "component---src-pages-content-2-3-index-js" */),
  "component---src-pages-content-3-1-index-js": () => import("./../../../src/pages/content3-1/index.js" /* webpackChunkName: "component---src-pages-content-3-1-index-js" */),
  "component---src-pages-content-3-2-index-js": () => import("./../../../src/pages/content3-2/index.js" /* webpackChunkName: "component---src-pages-content-3-2-index-js" */),
  "component---src-pages-content-3-3-index-js": () => import("./../../../src/pages/content3-3/index.js" /* webpackChunkName: "component---src-pages-content-3-3-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-waza-learning-index-js": () => import("./../../../src/pages/waza-learning/index.js" /* webpackChunkName: "component---src-pages-waza-learning-index-js" */),
  "component---src-pages-waza-list-index-js": () => import("./../../../src/pages/waza-list/index.js" /* webpackChunkName: "component---src-pages-waza-list-index-js" */),
  "component---src-pages-youtube-index-js": () => import("./../../../src/pages/youtube/index.js" /* webpackChunkName: "component---src-pages-youtube-index-js" */)
}

